html,
body {
    font-family: Montserrat;
    font-size: 16px;

    @media (max-width: 767px) {
        font-size: 13px;
    }
}

p {
    line-height: 1.8rem;
}

h1 {
    line-height: 1.2;
}

h3 {
    margin-bottom: 0.4rem;
}

a {
    color: #db64ff;

    &:visited {
        color: #db64ff;
    }
}

button {
    color: black;
}

.react-datepicker__navigation-icon {
    font-size: 0;
}

.react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon::before {
    top: -3px;
}

.react-datepicker__year-read-view--down-arrow {
    top: 3px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
    margin-left: -20px;
}

.react-datepicker__navigation--years {
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: '';
    display: block;
    height: 9px;
    top: 6px;
    width: 9px;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__year-option:first-of-type:hover,
.react-datepicker__year-option:last-of-type:hover {
    background-color: #f0f0f0;
}

.react-datepicker__year-option:first-of-type {
    .react-datepicker__navigation--years {
        transform: rotate(-45deg);
        margin-bottom: 15px;
    }
}

.react-datepicker__year-option:last-of-type {
    .react-datepicker__navigation--years {
        transform: rotate(135deg);
        margin-bottom: 15px;
    }
}

.button-freelancer-card {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
}

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 500px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flipped {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.1) !important;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: white;
}

.flip-card-back {
    background-color: white;
    position: relative;
    transform: rotateY(180deg) translateZ(1px);
    -webkit-transform: rotateY(180deg) translateZ(1px);
}

.rc {
    width: 100% !important;
    height: 100% !important;
}

.rc_body-row {
    min-height: 30px !important;
}

.rc_selected button{
    background-color: #db64ff !important;
    font-size: 16px !important;
}

.rc_body-days-of-month .rc_body-cell.rc_selected .rc_body-cell_value {
    background-color: #db64ff !important;
}

.rc_header_nav-prev span{
    font-size: 20px !important;
}

.rc_header_nav-next span{
    font-size: 20px !important;
}

.rc_body-days-of-month .rc_body-cell.rc_highlight:not(.rc_disabled) button{
    border-bottom: none !important;
    background-color: #c3b6c7;
}

.rc_body-days-of-month .rc_body-cell.rc_today:not(.rc_disabled) button{
    border-bottom: 1px solid black !important;
}

.rc_body-weekdays_cell {
    color: #c3b6c7 !important;
}

.rc_header_label span {
    font-size: 24px;
    font-weight: 600;
}

.details-day{
    position: absolute;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
}

.details-day::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
}

.details-day::-webkit-scrollbar-thumb {
    background: #c3b6c7;
    border-radius: 100px;
}


.details-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15%;
    font-size: 24px;
    font-weight: 600;
}

.details-element {
    padding: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 7px;
    align-items: center;
    justify-content: space-between;
    -webkit-transition: -webkit-transform 0.3s;
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
    position: relative;
    background: #ffffff;
    box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
}


.details-element:hover {
    transform: scale(1.007);
}

.details-element-level {
    padding: 5px 0;
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: 600;
    width: 66%;
    min-width: 200px;
    height: 25px;
}

.details-element-high {
    background-color: rgb(222, 255, 162);
}

.details-element-medium {
    background-color: rgb(199, 255, 97);
}

.details-element-onrequest {
    background-color: rgb(155, 199, 76);
}

.details-element-booking {
    color: white;
    background-color: #db64ff;
}

.details-element-request {
    color: white;
    background-color: #c3b6c7;
}

.details-element-time {
    min-width: 200px;
}

.time-picker {
    text-transform: lowercase !important;
}

.day-plus {
    font-size: 0.7em;
    color: #1c1c21;
}

.details-plus {
    position: absolute;
    top: 12.5px;
    right: 12.5px;
    background: #db64ff;
    width: 50px;
    height: 50px;
    border-radius: 9999px;
    font-size: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-weight: 400;
    color: white;
    cursor: pointer;
}

.details-plus > span {
    -webkit-transition: -webkit-transform 0.3s;
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
}

.details-plus:hover > span{
    transform: scale(1.3);
}

.detail-repeat-check {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.detail-repeat-check > label{
    display: block;
    margin-right: 15px;
    font-size: 0.6rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: rgb(204, 185, 210);
}

.repeatable-extra {
    -webkit-transition: height 4s;
    -moz-transition: height 4s;
    -ms-transition: height 4s;
    -o-transition: height 4s;
    transition: height 4s;
}

@media (min-width: 1200px) {
    .button-freelancer-card {
        flex-direction: row;
    }
}

a {
    text-decoration: none;
}

.phoneInput {
    border-radius: 3px !important;
    background-color: transparent !important;
    color: #1c1c21 !important;
    border: 1px solid !important;
    border-color: #ebebeb !important;
    padding-top: 22px !important;
    padding-bottom: 22px !important;
    padding-left: 70px !important;
    font-family: Montserrat,sans-serif !important;
    width: 100% !important;
    font-size: 1em !important;
}

.selected-flag {
    width: 60px !important;
    padding: 0 0 0 15px !important;
    background-color: white !important;
}

.react-tel-input .selected-flag .arrow {
    margin-top: -3x !important;
    border-left: 6px solid transparent !important;
    border-right: 6px solid transparent !important;
    border-top: 8px solid #db64ff !important;
    left: 25px !important;
}

.react-tel-input .selected-flag .up {
    border-top: none !important;
    border-bottom: 8px solid #555 !important;
}

.country-list {
    width: 300px !important;
    overflow-x: hidden;
}

.react-tel-input .country-list .search-box {
    width: 90%;
}
