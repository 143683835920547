@import 'variables.scss';

.react-toggle--checked .react-toggle-track {
  background-color: $primaryColor;

  &:hover {
    filter: brightness(110%);
  }
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $primaryColor;
  filter: brightness(110%);
}

.react-toggle--checked .react-toggle-thumb {
  border-color: #db64ff;
}