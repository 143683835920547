@import "../../assets/less/variables";
@import "../../assets/less/mixins";

.search-form {
  display: flex;
  flex-wrap: wrap;

  input {
    font-family: Montserrat;
    width: 100%;
    font-size: 16px;
    padding: 8px 10px;
  }

  .time-wrapper {
    display: flex;

    & > div {
      flex: 1;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .css-9gakcf-option {
    background-color: initial;
    color: initial;
  }
}