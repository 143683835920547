.ReactPasswordStrength {
  border-radius: 3px;
  background-color: transparent;
  color: #1c1c21 !important;
  border-color: #ebebeb !important;
  border: none !important;

  input {
    padding: 10px 12px;
  }
}

span.ReactPasswordStrength-strength-desc {
  width: 30%;
  font-size: 0.7em;
  top: 5px;
}

div.ReactPasswordStrength-strength-bar {
  top: 0;
  right: 0;
}