@import 'variables.scss';

.react-tabs__tab-list {
  border-radius: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0px;
  margin: auto !important;
  max-width: 400px;

  .react-tabs__tab {
    border-radius: 50px !important;
    border: 1px solid #c3b6c7;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 0.6em;
    padding: 4px 10px;
    bottom: 0;
    margin: 10px;
    height: calc(100% - 8px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #c3b6c7;
    line-height: 1.2;
    letter-spacing: 0;
    transition: background-color 0.2s ease;

    &.react-tabs__tab--selected {
      background-color: #9bc74c;
      border: 1px solid #9bc74c;
      color: #000000;
    }

    &:hover {
      filter: brightness(110%);
    }

    &:focus {
      box-shadow: none;

      &:after {
        display: none;
      }
    }
  }
}