@import '../../assets/less/variables.scss';
@import '../../assets/less/mixins.scss';

h2.list-title {
  margin-top: 55px;
  font-size: 24px;
}

.spacer {
  height: 10px;

  & + .availability-entry > span:first-child {
    color: #1c1c21;
  }
}

.availability-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 20px 0 15px;
  border-radius: 5px;
  font-weight: 600;
  color: #bdbdbd;

  & > * {
    flex: 1;
    margin: 0 10px;
  }

  span.confirm {
    margin: 0;
  }
}

.availability-entry {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 1px;
  padding: 16px 20px 16px 15px;
  border-radius: 5px;
  box-shadow: $defaultShadow;
  overflow: hidden;

  > span:first-child {
    font-weight: 700;
    color: transparent;
  }

  & > * {
    flex: 1;
    margin: 0 10px;
  }

  & > *:last-child {
    margin: 0;
  }

  .availability-grade {
    padding: 5px 0 3px;
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;

    &.high {
      background-color: $highColor;
    }

    &.medium {
      background-color: $mediumColor;
    }

    &.onrequest {
      background-color: $onrequestColor;
    }
  }

  button {
    width: 140px;
    flex: initial;

    &.confirm {
      border: none;
      background-color: $secondaryColor;
      color: #ffffff;
      border-radius: 50px;
      font-weight: 600;
      font-size: 14px;
      padding: 11px;

      &:hover {
        background-color: darken($secondaryColor, 10%);
      }
    }
  }

  .more-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      cursor: pointer;
    }
  }
}