@font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    src: local('Montserrat'), url(../fonts/Montserrat-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 200;
    src: local('Montserrat'), url(../fonts/Montserrat-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    src: local('Montserrat'), url(../fonts/Montserrat-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src: local('Montserrat'), url(../fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    src: local('Montserrat'), url(../fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: local('Montserrat'), url(../fonts/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: local('Montserrat'), url(../fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    src: local('Montserrat'), url(../fonts/Montserrat-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    src: local('Montserrat'), url(../fonts/Montserrat-Black.ttf) format('truetype');
}