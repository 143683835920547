@import 'variables.scss';

.rbc-toolbar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px 70px;

  @media #{$phone} {
    grid-template-rows: 50px 85px;
  }

  .rbc-btn-group {

    &:first-child button:first-child {
      background-color: #c3b6c7;
    }

    &:first-child {
      grid-column: 1;
      grid-row: 2;

      @media #{$phone} {
        align-self: baseline;
      }
    }

    &:last-child {
      grid-column: 2;
      grid-row: 2;
      text-align: right;

      @media #{$phone} {
        display: grid;
        align-self: baseline;

        button {
          border-radius: 5px;
        }
      }
    }
  }

  .rbc-toolbar-label {
    font-size: 2em;
    font-weight: bold;
    grid-column: 1 / span 2;
    grid-row: 1;
    text-align: left;
    padding: 0;

    @media #{$phone} {
      font-size: 1.3em;
    }
  }

  button {
    font-weight: bold;
    font-size: 0.8em;
    height: 29px;

    svg {
      margin-bottom: -2px;
      transform: scale(1.5);
    }

    &:active {
      box-shadow: none;
    }

    &:focus {
      outline: 0;
    }

    &.rbc-active, &.rbc-active:focus {
      background-color: #c3b6c7;
      border-color: #c3b6c7;
      box-shadow: none;
      outline: 0;
    }

    @media #{$phone} {
      font-size: 0.7em;
    }
  }
}

.rbc-time-view {

  .rbc-row {
    @media #{$phone} {
      min-height: 40px;
    }
  }

  .rbc-header {
    @media #{$phone} {
      padding: 0 5px;
      white-space: normal;
      text-align: left;
    }
  }
}

.rbc-month-view,
.rbc-time-view {
  background-color: #ffffff;
  border-radius: 5px;

  .rbc-event-content {
    @media #{$phone} {
      font-size: 0.8em;
    }
  }
}


.rbc-day-slot {

  .rbc-events-container {
    @media #{$phone} {
      margin: 0;
    }
  }
}

.rbc-today {
  background-color: #c3b6c7;
}

.rbc-off-range-bg {
  background-color: #f3f3f3;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ececec;
}

.rbc-time-slot {
  
  .rbc-label {
    text-transform: lowercase;
  }
}

.rbc-event-label {
  text-transform: lowercase;
}

.rbc-show-more  {
  color: black;
}