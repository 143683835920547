$primaryColor: #db64ff;
$accentColor: #db64ff;
$secondaryColor: #c3b6c7;

$highColor: #deffa2;
$mediumColor: #c7ff61;
$onrequestColor: #9bc74c;

$defaultShadow: 3px 3px 9px 3px rgba(223, 220, 220, 0.35);
$defaultShadowDark: 1px 1px 10px 2px rgba(0,0,0,0.15);

$defaultBorderRadius: 5px;

$phone:   "(max-width: 767px)";
$tablet:  "(max-width: 1167px)";