@import 'variables.scss';

.jobbelganger-background {
  background: #ffffff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: $defaultShadow;
  margin-bottom: 30px;
}

.jobbelganger-background-dark-shadow {
  background: #ffffff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: $defaultShadowDark;
  margin-bottom: 30px;
}

// .jobbelganger-button($color) {
//   height: 60px;
//   border: none;
//   background-color: $color;
//   color: #ffffff;
//   font-weight: 600;
//   font-size: 14px;

//   &:hover {
//     background-color: darken($color, 10%);
//   }
// }

// .jobbelganger-secondary-button($color) {
//   border: none;
//   background: none;
//   color: $color;
//   padding: 20px;

//   &:hover {
//     color: darken($color, 10%);
//   }
// }

// .jobbelganger-round-button($color) {
//   border: none;
//   background-color: $color;
//   color: #ffffff;
//   border-radius: 50px;
//   font-weight: 600;
//   font-size: 14px;
//   padding: 11px;

//   &:hover {
//     background-color: darken($color, 10%);
//   }
// }

.jobbelganger-toggle-button {
  background-color: #c3b6c71f;
  border: 3px solid transparent;
  border-radius: 50px;
  padding: 6px 30px;
  color: $secondaryColor;
  font-size: 0.9em;
  font-weight: 600;
  margin-right: 10px;
  margin-bottom: 12px;

  &:hover {
    background-color: rgba(195, 182, 199, 0.41);
  }

  &.active {
    border: 3px solid $onrequestColor;
    background-color: $onrequestColor;
    color: #ffffff;
  }
}

.jobbelganger-input {
  font-family: Montserrat;
  border: none;
  border-bottom: 1px solid #c3b6c7;
  border-radius: 0;
  padding: 10px 0 5px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 18px;
  -webkit-appearance: none;
}

.jobbelganger-close-button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #ffffff;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 1px #0000004d;
  cursor: pointer;
}